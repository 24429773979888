import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

import Card from './Card';
import { readCard } from './services/firebase';
// import logo from './logo.svg';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

// The first card ID: yP8mdwv2Jgg8jEXWioJM

function App() {
  const [card, setCard] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const path = window.location.pathname;
  const cardId = path.substring(1);

  useEffect(() => {
    const get = async () => {
      try {
        const data = await readCard(cardId);
        setCard(data);
      } catch (err) {
        console.error(err);
      }
      setIsLoading(false);
    };
    get();
  }, [cardId]);

  return (
    <Container>
      {isLoading ? (
        <Row className="mt-5 text-center">
          <Col>
            <Spinner />
          </Col>
        </Row>
      ) : (
        <Card card={card} />
      )}
    </Container>
  );

  /*
  console.debug('card', card);
  if (card) {
    return <Card card={card} />
  }
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
  */
}

export default App;
