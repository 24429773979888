import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore, doc, getDoc } from 'firebase/firestore/lite';

// from Firebase Console
const firebaseConfig = {
  apiKey: 'AIzaSyCN1F_MhhBaD-lY81z2tAbvpnkv8nvJ3oM',
  authDomain: 'gift-cards-by-codeboy.firebaseapp.com',
  projectId: 'gift-cards-by-codeboy',
  storageBucket: 'gift-cards-by-codeboy.appspot.com',
  messagingSenderId: '1011308659995',
  appId: '1:1011308659995:web:6bb7dad8e63dc02864ffbe',
  measurementId: 'G-4YGY1TYX6V',
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export async function readCard(cardId) {
  const docRef = doc(db, 'cards', cardId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  }
  console.error(`Data not found: ${cardId}`);
  return undefined;
}
