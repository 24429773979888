import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import './Card.css';
import logo from './logo.svg';
import Timestamp from './Timestamp';

export default function GiftCard({ card }) {
  if (!card) {
    return (
      <Row className="mt-3">
        <Col className="text-center">Tarkista, että osoite on oikein.</Col>
      </Row>
    );
  }
  console.debug('card', card);
  return (
    <Row className="mt-3">
      <Col className="d-flex justify-content-center">
        <Card style={{ width: '26rem' }}>
          <Card.Img variant="top" src={logo} className="gift-card-image" />
          <Card.Body>
            <Card.Title>Hierontalahjakortti {card.value} €</Card.Title>
            <Card.Text>
              Koulutettu Hieroja Jaakola
              <br /> Näytä tämä lahjakortti, kun haluat käyttää sitä.
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <small className="text-muted">
              Ostettu <Timestamp value={card.created} />
            </small>
          </Card.Footer>
        </Card>
      </Col>
    </Row>
  );
}
